import { WelkinEncounterTemplateNames } from '../welkin';
import { z } from 'zod';

export const eligibleCliniciansParamsSchema = z.object({
   templateName: z.nativeEnum(WelkinEncounterTemplateNames),
});

export type EligibleCliniciansParamsDto = z.infer<
   typeof eligibleCliniciansParamsSchema
>;
