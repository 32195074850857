import { z } from 'zod';
import { type NmiOptions } from './nmi.types';

export const nmiOptionsSchema: z.ZodType<NmiOptions> = z.object({
   // This is for automated retry payment
   triggeredByAutomation: z.boolean().optional(),
   // This is for initial transaction
   noPushToPaymentFailed: z.boolean().optional(),
   // This is for recurring transactions
   noPushToPaymentFailedOnRecurring: z.boolean().optional(),
   noInvoice: z.boolean().optional(),
});

export const nmiMerchantFieldsSchema = z.object({
   patientId: z.string().uuid().or(z.literal('')).optional(),
   retryTransactionId: z.string().optional(),
   noShowOrCancellation: z.string().optional(),
   welkinPaymentCdtId: z.string().uuid().or(z.literal('')).optional(),
   chosenTherapistId: z.string().uuid().or(z.literal('')).optional(),
   source: z.string().optional(),
   receiptId: z.string().optional(),
   insurerName: z.string().optional(),
   appointmentDate: z.string().optional(),
   ccLast4Digits: z.string().length(4).or(z.literal('')).optional(),
   patientResponsibilityAmount: z.string().optional(),
   freeText: z.string().optional(),
   freeText2: z.string().optional(),
   options: nmiOptionsSchema.optional(),
   paymentFailedCdtId: z.string().uuid().or(z.literal('')).optional(),
   missedAppointmentCdtId: z.string().uuid().optional(),
   encounterId: z.string().uuid().optional(),
});

export type NmiMerchantFieldsParams = z.infer<typeof nmiMerchantFieldsSchema>;
