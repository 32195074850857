import { z } from 'zod';
import {
   nmiOptionsSchema,
   type NmiTransactionCreatedResponseDto,
} from '../nmi';

export const retryPaymentSchema = z.object({
   paymentFailedCdtId: z.string().uuid(),
   paymentToken: z.string().optional(),
   // Don't send vault data for existing patients, don't create a new customer vault entry
   // Used for Apple Pay and Google Pay
   noCustomerVault: z.boolean().optional(),

   // save to Customer Vault if true
   saveCreditCard: z.coerce.boolean().optional().default(true),

   // additional options (triggeredByAutomation)
   options: nmiOptionsSchema.optional(),
});

export const retryPaymentDevSchema = retryPaymentSchema.extend({
   patientId: z.string().uuid(),
});

export const retryPaymentAdminSchema = retryPaymentSchema.pick({
   paymentFailedCdtId: true,
});

export type RetryPaymentDto = z.infer<typeof retryPaymentSchema>;
export type RetryPaymentAdminDto = z.infer<typeof retryPaymentAdminSchema>;

export type RetryPaymentResponse = {
   //cartId: string;
   transactionId: string;
   orderId?: number;
   transaction: NmiTransactionCreatedResponseDto;
};
