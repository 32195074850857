import { type ClinicianResponseDto } from '../clinicians';
import { WelkinEncounterTemplateNames } from '../welkin';
import {
   type TimetapClientDateTime,
   type TimetapStaffDateTime,
} from './api.types';
import { type TimetapDate } from './shared.types';
import { z } from 'zod';
import {
   dateField,
   dateTimeField,
   intExtendedField,
} from '../common/zod.fields';

export const timetapLocationAvailabilityParamsSchema = z.object({
   locationId: intExtendedField,
   serviceId: intExtendedField,
   startDate: dateField,
   endDate: dateField,
   templateName: z.nativeEnum(WelkinEncounterTemplateNames),
});

export type TimetapLocationAvailabilityParams = z.infer<
   typeof timetapLocationAvailabilityParamsSchema
>;

const timetapStaffAvailabilityParamsSchema = z.object({
   staffId: intExtendedField,
   locationId: intExtendedField,
   serviceId: intExtendedField,
   duration: intExtendedField.optional(),
   resourceId: intExtendedField.optional(),
});

export type TimetapStaffAvailabilityParams = z.infer<
   typeof timetapStaffAvailabilityParamsSchema
>;

export const timetapMonthlyStaffAvailabilityParamsSchema =
   timetapStaffAvailabilityParamsSchema.extend({
      year: intExtendedField,
      month: intExtendedField,
   });

export type TimetapMonthlyStaffAvailabilityParams = z.infer<
   typeof timetapMonthlyStaffAvailabilityParamsSchema
>;

export const timetapDailyStaffAvailabilityParamsSchema =
   timetapStaffAvailabilityParamsSchema.extend({
      date: dateField,
   });

export type TimetapDailyStaffAvailabilityParams = z.infer<
   typeof timetapDailyStaffAvailabilityParamsSchema
>;

export interface TimetapTimeSlot {
   // Staff date time appears to be related to location timezone
   staffStartDate: string;
   staffEndDate: string;
   staffStartTime: number;
   staffEndTime: number;

   // @NOTE: this appears to be the same as staff times above
   startTime: number;
   endTime: number;

   // Client date time looks related to the business timezone
   clientStartDate: string;
   clientEndDate: string;
   clientStartTime: number;
   clientEndTime: number;
   timeString: string;
   units: {
      professionalId: number;
      locationId: number;
      reasonId: number;
      resourceId: number | null;
      bundleResourceIdList: null; // ??
      tandemStaffIdList: null;
      roundRobin: boolean;
   }[];
   staffStartDateTimeUTC: number;
   staffEndDateTimeUTC: number;
}

export interface TimetapLocationAvailabilityResultDto {
   date: string;
   timeSlots: TimetapTimeSlot[];
   clustered: boolean;
}

export interface TimetapStaffMonthlyAvailabilityResultDto {
   businessId: number;
   locationList: null;
   staffList: null;
   month: number;
   year: number;
   startDate: TimetapDate;
   endDate: TimetapDate;
   filledDays: []; // ??
   openDays: TimetapDate[];
   vacationDays: []; // ??
   currentMonth: number;
}

export type TimetapStaffDailyAvailabilityResultDto = TimetapTimeSlot;

export const timetapNextAvailableAppointmentForLocationsParamsSchema = z.object(
   {
      locationIds: z.array(intExtendedField),
      staffId: intExtendedField.optional(),
      serviceId: intExtendedField,
   },
);

export type TimetapNextAvailableAppointmentForLocationsParams = z.infer<
   typeof timetapNextAvailableAppointmentForLocationsParamsSchema
>;

export interface TimetapNextAvailableAppointmentForLocationResultDto {
   locationId: number;
   nextAvailableDate: string | null;
}

export const timetapStaffAvailabilityForDateParamsSchema = z.object({
   staffId: intExtendedField,
   date: dateField,
   locationId: intExtendedField,
   serviceId: intExtendedField,
});

export type TimetapStaffAvailabilityForDateParams = z.infer<
   typeof timetapStaffAvailabilityForDateParamsSchema
>;

export interface TimetapStaffAvailabilityForDateResultDto {
   clinician: { name: string };
   slots: Timeslot[];
}

const timetapMultiStaffAvailabilityBaseParamsSchema = z.object({
   locationId: intExtendedField,
   serviceId: intExtendedField,
   clinicianIds: z.array(z.string()).optional(),
   templateName: z.nativeEnum(WelkinEncounterTemplateNames),
});

export type TimetapMultiStaffAvailabilityBaseParams = z.infer<
   typeof timetapMultiStaffAvailabilityBaseParamsSchema
>;

export const timetapMultiStaffAvailabilityParamsSchema =
   timetapMultiStaffAvailabilityBaseParamsSchema.extend({
      clientStartTime: dateTimeField,
      clientEndTime: dateTimeField,
   });

export type TimetapMultiStaffAvailabilityParams = z.infer<
   typeof timetapMultiStaffAvailabilityParamsSchema
>;

export const timetapMultiStaffAvailabilityByMonthParamsSchema =
   timetapMultiStaffAvailabilityBaseParamsSchema.extend({
      year: intExtendedField,
      month: intExtendedField,
   });

export type TimetapMultiStaffAvailabilityByMonthParams = z.infer<
   typeof timetapMultiStaffAvailabilityByMonthParamsSchema
>;

export type Timeslot = {
   date: string;
   startTime: string;
   endTime: string;
   staff: TimetapStaffDateTime;
   client: TimetapClientDateTime;
};

export interface TimetapMultiStaffAvailabilityResultDto {
   pinToTop: boolean;
   staffId: number; // Timetap staff id
   slots: Timeslot[];
   clinician: ClinicianResponseDto;
   nextAvailableDate: string | null; // date only
}

export type TimetapMultiStaffAvailabilityResponseDto = {
   availability: TimetapMultiStaffAvailabilityResultDto[];
   insurance: boolean;
};

export interface TimetapMultiStaffAvailabilityByMonthResultDto {
   pinToTop: boolean;
   staffId: number;
   dates: string[];
   clinician: ClinicianResponseDto;
}

export type TimetapMultiStaffAvailabilityByMonthResponseDto = {
   availability: TimetapMultiStaffAvailabilityByMonthResultDto[];
   insurance: boolean;
};
