export enum EncounterDispositionCodes {
   Completed = 'Appointment completed',
   PatientMissedAppointment = 'Patient no show',
   PatientShowedButRescheduled = 'Patient showed but needed to reschedule',
   ClinicianCancelled = 'Clinician cancelled',
   PatientCanceledWithin24Hours = 'Patient canceled within 24 hours',
   PatientCanceledWithin48Hours = 'Patient canceled within 48 hours',
   AdminCancelled = 'Admin cancelled',
   OutstandingBalance = 'Outstanding balance',
   PatientCancelledNoFee = 'Patient cancelled without a fee',
}

export const MissedAppointmentDispositionCodes = [
   EncounterDispositionCodes.PatientShowedButRescheduled,
   EncounterDispositionCodes.PatientMissedAppointment,
   EncounterDispositionCodes.PatientCanceledWithin24Hours,
];

// Without metadata
export interface EncounterDispositionFields {
   'disposition-code': EncounterDispositionCodes | null;
   'cpt-code': unknown;
   note: string | null;
   'uicedf-disposition-code-admin': unknown;
   'uicedf-admin-notes': string | null;
   'uicedf-zoom-host-url': string | null;
   'uicedf-zoom-patient-url': string | null;
   'uicedf-zoom-meeting-id': string | null;
   'uicedf-host-proxy-url': string | null;
   'uicedf-last-start': string | null;
   'uicedf-last-end': string | null;
   'uicedf-extra-time-event-id': string | null;
   // Set to true after email that fixes ICS link was sent.
   'uicedf-zoom-link-email-sent': boolean | null;
   // Used to prevent from sending multiple emails on disposition update or appointment cancellation events
   'uicedf-patient-cancellation-email-sent': boolean | null;
   // Timetap appointment id
   'uicedf-timetap-appointment-id': number | null;
   'uicedf-insurance-cdt-id': string | null;
}

interface EncounterDisposition extends EncounterDispositionFields {
   note: string | null;
   external_guid: string | null;
   source_type: string; // always ENCOUNTER?
   external_id: string | null;
   created_at: string;
   updated_at: string;
   created_by: string;
   created_by_name: string;
   updated_by: string;
   updated_by_name: string;

   id: string;
   source_id: string;
   source_name: string; // always __encounter__?
}

export interface EncounterDispositionResponseDto {
   id: string;
   patientId: string;
   cdtId: string;
   version: number;
   jsonBody: EncounterDisposition;
   cdtName: string; // always __encounter_disposition__?
}
