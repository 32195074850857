import { z } from 'zod';
import { NmiSource } from '../nmi';
import { PaymentCode } from '../products';

export enum FailedPaymentsRetryStatus {
   SUCCESS = 'SUCCESS',
   PENDING = 'PENDING',
   FAILED = 'FAILED',
   NONE = 'NONE', //default
}

export const failedPaymentsSchema = z.object({
   paymentFailedCdtId: z.string().optional(),
   transactionId: z.string().optional(), //NOTE: for admin use when no customer vault exists
   transactionAmount: z.string(),
   ccNumber: z.string(),
   orderId: z.string().optional(),
   retryStatus: z.nativeEnum(FailedPaymentsRetryStatus),
   retryTransactionId: z.string().optional(),
   retryCount: z.number().optional(),
   nextRetryDate: z.string().optional(),
   transactionType: z.nativeEnum(NmiSource).optional(), //"source": "api", NmiSource
   subscriptionId: z.string().or(z.null()),
   // It will be sent only when relevant
   installments: z
      .object({
         installmentAmount: z.number(),
         planPayments: z.number(),
         paymentCode: z.nativeEnum(PaymentCode),
      })
      .or(z.null()),
});
export type FailedPaymentDto = z.infer<typeof failedPaymentsSchema>;

export type FailedPaymentDtoWithoutInstallments = Omit<
   FailedPaymentDto,
   'installments'
>;

export type PaymentEventPayload = FailedPaymentDtoWithoutInstallments & {
   patientId: string;
};

export const failedPaymentByIdResponseDto = failedPaymentsSchema.extend({
   products: z.array(
      z.object({
         title: z.string(),
      }),
   ),
   freeNote: z.string().optional(),
   freeNote2: z.string().optional(),
});

export type FailedPaymentByIdResponseDto = z.infer<
   typeof failedPaymentByIdResponseDto
>;
